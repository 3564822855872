import { useCallback, useEffect } from 'react'
import { Variant } from './types'
import { PhotoProductCategoryId, ProductCatalog } from '../../../clients/productCatalog'
import Button from '../../../shared/buttons/Button'
import FooterDisclaimer from '../../../shared/FooterDisclaimer'
import StickyFooter from '../../../shared/StickyFooter'
import BannerSection from './BannerSection'
import CategoryProductsCarousel from './CategoryProductsCarousel'
import CategoryTiles from './CategoryTiles'
import LargeBannerSection from './LargeBannerSection'
import FaqSection from './FaqSection'
import ShippingInfo from './ShippingInfo'
import { useNavigate } from 'react-router-dom'
import { useApi } from '../../../hooks/useApi'
import useFamilyAlbumUserAgent from '../../../hooks/useFamilyAlbumUserAgent'
import useAppInterface from '../../../hooks/useAppInterface'
import CuratedPhotosHeaderContent from './CuratedPhotosHeaderContent'
import MilestoneHeader, { MilestoneHeaderType } from './MilestoneHeader'
import { useCuratedPhotosContext } from '../contexts/CuratedPhotosContext'

export default function PhotoProductsLanding({
  variant = 'DEFAULT',
  supportEmail,
  productCatalog,
  headerType = 'default',
  familyAlbumUserId,
  childNames,
}: {
  variant?: Variant
  supportEmail: string
  productCatalog: ProductCatalog
  headerType: string
  familyAlbumUserId?: string
  childNames?: string[]
}) {
  const {
    recordUserAction: {
      photoProducts: { recordDidTapPodC2A, recordDidViewPodLp },
    },
  } = useApi()
  const {
    curatedPhotosForPhotoProducts,
    arePhotosForPhotoProductsLoaded,
    isUsingFallbackForPhotoProducts,
  } = useCuratedPhotosContext()

  const navigate = useNavigate()
  const staticFileFolder = '/photo-products/landing/default'

  const { showWebViewCloseButton, showWebViewCloseButtonWithDialog } = useAppInterface()

  let milestoneHeaderType: MilestoneHeaderType | undefined
  let milestoneChildName: string | undefined
  if (
    familyAlbumUserId &&
    childNames &&
    childNames.length > 0 &&
    (Object.values(MilestoneHeaderType) as string[]).includes(headerType)
  ) {
    milestoneHeaderType = headerType as MilestoneHeaderType
    milestoneChildName = childNames[0]
  }

  const onC2aClick = useCallback(
    (clickedElement: string) => {
      if (!milestoneHeaderType) {
        showWebViewCloseButtonWithDialog()
      }
      navigate('/photo-products/categories')
      recordDidTapPodC2A({ clickedElement })
    },
    [navigate, recordDidTapPodC2A]
  )

  const onCategoryClick = useCallback(
    (category: PhotoProductCategoryId, clickedElement: string) => {
      const numberOfProductsInSelectedCategory = productCatalog[category].products.length
      const skipPodSubCategory = numberOfProductsInSelectedCategory === 1
      recordDidTapPodC2A({ clickedElement, skippedPodSubCategory: skipPodSubCategory })
      if (!milestoneHeaderType) {
        showWebViewCloseButtonWithDialog()
      }
      if (skipPodSubCategory) {
        navigate(`/photo-products/add/${productCatalog[category].products[0].productId}`, {
          state: { source: '' },
        })
      } else {
        navigate(`/photo-products/category/${category}`)
      }
    },
    [navigate, recordDidTapPodC2A]
  )

  useEffect(() => {
    if (!milestoneHeaderType) {
      showWebViewCloseButton()
    }
    if (arePhotosForPhotoProductsLoaded) {
      recordDidViewPodLp({
        pageVariant: variant,
        isShowingUserPhotos: !isUsingFallbackForPhotoProducts,
      })
    }
  }, [arePhotosForPhotoProductsLoaded, isUsingFallbackForPhotoProducts])

  return (
    <>
      <div className="text-center text-base font-bold text-white bg-blue-12 h-8 content-center">
        Up to 30% OFF All Photo Gifts
      </div>
      {milestoneHeaderType && milestoneChildName ? (
        <MilestoneHeader
          headerType={milestoneHeaderType}
          name={milestoneChildName}
          curatedPhotos={curatedPhotosForPhotoProducts}
          isUsingFallback={isUsingFallbackForPhotoProducts}
        />
      ) : (
        <>
          <header className="relative">
            <CuratedPhotosHeaderContent
              staticFileFolder={staticFileFolder}
              curatedPhotos={curatedPhotosForPhotoProducts}
              isUsingFallback={isUsingFallbackForPhotoProducts}
            />
          </header>
          <BannerSection />
        </>
      )}
      <main className="space-y-4">
        <CategoryTiles
          categories={['MUGS', 'SOCKS', /*'STICKERS',*/ 'PUZZLES']}
          staticFileFolder={staticFileFolder}
          selectCategory={onCategoryClick}
        />
        <CategoryProductsCarousel
          title="Mugs"
          category="MUGS"
          staticFileFolder={staticFileFolder}
          numberOfPhotos={6}
          selectCategory={onCategoryClick}
        />
        <CategoryProductsCarousel
          title="Socks"
          category="SOCKS"
          staticFileFolder={staticFileFolder}
          numberOfPhotos={6}
          selectCategory={onCategoryClick}
        />
        {/*
        <CategoryProductsCarousel
          title="Stickers"
          category="STICKERS"
          staticFileFolder={staticFileFolder}
          numberOfPhotos={6}
          selectCategory={onCategoryClick}
        />
        */}
        <CategoryProductsCarousel
          title="Puzzles"
          category="PUZZLES"
          staticFileFolder={staticFileFolder}
          numberOfPhotos={6}
          selectCategory={onCategoryClick}
        />
      </main>
      <LargeBannerSection
        staticFileFolder={staticFileFolder}
        title="Up to 30% OFF"
        description="All Custom Photo Gifts"
        callToAction="Create Now"
        onCallToActionClick={() => onC2aClick('large-banner')}
      />
      <ShippingInfo />
      <FaqSection supportEmail={supportEmail} />
      <footer className="px-4 pb-8 mt-12">
        <p>
          Have questions? Contact us at <br />
          <a className="text-fa-primary" href={`mailto:${supportEmail}`}>
            {supportEmail}
          </a>
        </p>
        <FooterDisclaimer />
      </footer>
      <StickyFooter>
        <Button
          onClick={() => onC2aClick('footer')}
          className="p-2 block w-full"
          colorVariant="primary"
        >
          Get Started
        </Button>
      </StickyFooter>
    </>
  )
}
